<template>
  <base-section
    id="portfolio-desc"
    class="px-5"
  >
    <base-section-heading
      title="프로젝트 목록"
    />
    <v-row>
      <v-col
        v-for="(info, n) in information"
        :key="n"
        cols="12"
        md="4"
        sm="12"
      >
        <v-card
          class="mx-auto black--text grey lighten-2"
        >
          <v-img
            :src="info.src"
            :height="$vuetify.breakpoint.mdAndUp ? 300 : 200"
          />

          <v-card-title>
            {{ info.title }}
          </v-card-title>
          <v-card-text
            class="black--text v-space_control"
          >
            {{ info.text }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPortfolioDesc',
    data () {
      return {
        information: [
          {
            title: '에어컨 부품 창고',
            text: '물류 창고, WMS 시스템',
            src: require('@/assets/ronghao_portfolio1.jpg'),
          },
          {
            title: '우한 Lear 물류창고',
            text: '물류 창고, WMS 시스템, 보조 라인',
            src: require('@/assets/ronghao_portfolio2.jpg'),
          },
          {
            title: '난징 LG Chem 물류창고',
            text: '물류 창고, WMS 시스템, 보조 라인',
            src: require('@/assets/ronghao_portfolio3.jpg'),
          },
          {
            title: '베이징 Lear 물류창고',
            text: 'AGV, WIFI, 소프트웨어, 렉',
            src: require('@/assets/ronghao_portfolio5.jpg'),
          },
          {
            title: '충칭 Lear 물류창고',
            text: 'AGV, WIFI, 소프트웨어',
            src: require('@/assets/ronghao_portfolio6.jpg'),
          },
          {
            title: '선양 SJA 물류창고',
            text: 'AGV, WIFI, 소프트웨어',
            src: require('@/assets/ronghao_portfolio7.jpg'),
          },
          {
            title: '업데이트 예정',
            text: '',
            src: require('@/assets/ronghao_portfolio4.jpg'),
          },
        ],
      }
    },
  }
</script>
<style type="text/css">
.v-card__text {
  letter-spacing: 0.0rem;
}
</style>
